import React from 'react';
import ReactDOM from 'react-dom/server';

const NoScript = ({ children }) => {
  const staticMarkup = ReactDOM.renderToStaticMarkup(children);

  return <noscript dangerouslySetInnerHTML={{ __html: staticMarkup }} />;
};

export default NoScript;
