import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import NoScript from 'common/NoScript/NoScript';

import floodLightService from 'utils/floodlightService/floodLightService';

import { eventTypes } from './constants';
import { IFloodlightTrackerProps } from './model';

const FloodlightTracker: FC<IFloodlightTrackerProps> = ({ events, pageUrl }) => {
  useEffect(() => {
    const visitEvent = events[eventTypes.VISIT];

    if (visitEvent) {
      floodLightService.fireFloodlightEvent('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: visitEvent.sendTo.replace('%TAG%', floodLightService.tag),
      });
    }
  }, []);

  return (
    <NoScript>
      {Object.values(events).map(({ type, noScriptUrl }) => (
        <img
          key={type}
          src={floodLightService.getNoScriptUrl(noScriptUrl, pageUrl)}
          width="1"
          height="1"
          alt=""
        />
      ))}
    </NoScript>
  );
};

export const query = graphql`
  fragment FragmentFloodlightEvents on TFloodlightEvent {
    properties {
      type
      sendTo
      noScriptUrl
    }
  }
`;

export default FloodlightTracker;
