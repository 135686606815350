import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Container from 'layout/Container';
import ProductBanner from 'components/ProductBanner';
import ProductDetails from 'components/ProductDetails';
import PromoElement from 'components/PromoElement';
import FeaturedProducts from 'components/FeaturedProducts';
import ProductsFinder from 'components/ProductsFinder';
import BreadCrumbs from 'common/BreadCrumbs';
import FeaturedArticles from 'components/FeaturedArticles';
import FloodlightTracker from 'common/FloodlightTracker';

import { isNotEmptyArray } from 'utils/array';
import { getResponsiveImageData } from 'utils/images';
import floodLightService from 'utils/floodlightService/floodLightService';

import { AppImages, Floodlight } from '@shared/types';
import { IPropsProductPage } from './models';
import Helpers from './helpers';

import './ProductPage.scss';
import './ShadowProductPage.scss';

// activeColor is a part of the future ProductFamily functionality
const activeColor = '#FDBD21';

const ProductPage: FC<IPropsProductPage> = ({
  data: {
    pageData,
    pdpSettings,
    featuredProductsByLink,
    featuredProductsByTag,
    featuredArticlesByLink,
    featuredArticlesByTag,
  },
  pageContext: { breadcrumbs, lang },
  location,
}) => {
  const { seo, defaultCompositions, featuredArticlesList, useFloodlight, floodlightEvents } =
    pageData;
  useEffect(() => {
    useFloodlight && floodLightService.loadFloodlightGlobal();
  }, []);

  const productCarouselItems: AppImages.IFluidData[][] = [
    [...getResponsiveImageData(pageData.defaultProductImage, pageData.mobileDefaultProductImage)],
  ];

  if (pageData?.mobileAdditionalProductImages?.length) {
    pageData.mobileAdditionalProductImages.forEach((mobileImage, index) => {
      if (pageData?.additionalProductImages?.[index]) {
        productCarouselItems.push(
          getResponsiveImageData(pageData.additionalProductImages[index].image, mobileImage.image)
        );
      }
    });
  }

  const eventTypes: Floodlight.EventTypes =
    floodlightEvents?.reduce(
      (type, event) => ({
        ...type,
        [event.properties.type]: event.properties,
      }),
      {}
    ) || {};

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={seo}
      className="product-page-layout footer-buffer"
    >
      {Object.keys(eventTypes).length ? (
        <FloodlightTracker events={eventTypes} pageUrl={location.href} />
      ) : null}

      <Container>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
      </Container>
      <div data-test="ProductPage" className="product-page">
        <ProductBanner
          title={pageData.titleMedium}
          dir={defaultCompositions.siteSettings.dir[0]}
          mainFeaturesItems={Helpers.parseProductFeatures(pageData.productFeaturesMain)}
          secondaryFeaturesItems={Helpers.parseProductFeatures(pageData.productFeaturesSecondary)}
          dietaryTitle={pdpSettings.dietaryFeaturesTitle}
          dietaryItems={Helpers.parseProductFeatures(pageData.dietaryFeatures)}
          productCarouselItems={productCarouselItems}
          buttonBuyTitle={pdpSettings.buttonBuyTitle}
          buyCTA={pageData.buyCTA}
          buyPopup={pageData.buyPopup}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          closeAriaLabel={defaultCompositions.siteSettings.closeAriaLabel}
          isStickyBuyButton
          floodlightEvents={eventTypes}
        />
        <ProductDetails productDetails={pageData.productDetails} />
        <FeaturedProducts
          featuredProducts={pageData.featuredProductsList}
          featuredProductsByLink={featuredProductsByLink.nodes}
          featuredProductsByTag={featuredProductsByTag.nodes}
          productsOrder={pageData?.featuredProductsList?.[0]?.properties?.selectedProductsIds || []}
          dir={defaultCompositions.siteSettings.dir[0]}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          activeColor={activeColor}
        />
        {pdpSettings?.startQuiz?.[0] ? (
          <div
            className="product-page__products-finder"
            style={
              activeColor
                ? ({
                    backgroundColor: activeColor,
                  } as React.CSSProperties)
                : undefined
            }
          >
            <Container>
              <ProductsFinder
                startQuiz={pdpSettings.startQuiz}
                productQuizData={defaultCompositions.productQuiz}
                ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
                ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
                lang={lang}
              />
            </Container>
          </div>
        ) : null}
        {isNotEmptyArray(featuredArticlesList) ? (
          <FeaturedArticles
            featuredArticlesList={featuredArticlesList}
            featuredArticlesByLink={featuredArticlesByLink.nodes}
            featuredArticlesByTag={featuredArticlesByTag.nodes}
            dir={defaultCompositions.siteSettings.dir[0]}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          />
        ) : null}
        {pageData?.promoElement?.length ? (
          <PromoElement promoElement={pageData.promoElement} />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query (
    $link: String!
    $lang: String!
    $selectedProductsLinks: [String]
    $selectedProductTag: Int
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
  ) {
    pageData: product(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      sku
      titleMedium
      defaultProductImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 380, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      mobileDefaultProductImage: defaultProductImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 230, fit: CONTAIN, quality: 30) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      additionalProductImages {
        image {
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 480, fit: CONTAIN) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageAlt
      }
      mobileAdditionalProductImages: additionalProductImages {
        image {
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 230, fit: CONTAIN, quality: 30) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      productFeaturesMain {
        ...FragmentProductFeatures
      }
      productFeaturesSecondary {
        ...FragmentProductFeatures
      }
      dietaryFeatures {
        ...FragmentProductFeatures
      }
      buyCTA {
        ...FragmentButton
      }
      buyPopup {
        ...FragmentStoresList
      }
      productDetails {
        ...FragmentProductDetails
      }
      promoElement {
        ...FragmentPromoElement
      }
      featuredProductsList {
        ...FragmentFeaturedProducts
      }
      featuredArticlesList {
        ...FragmentFeaturedArticles
      }
      useFloodlight
      floodlightEvents {
        ...FragmentFloodlightEvents
      }
    }
    pdpSettings: pdpSettings(lang: { eq: $lang }) {
      dietaryFeaturesTitle
      buttonBuyTitle
      startQuiz {
        ...FragmentStartQuizSection
      }
    }
    featuredProductsByLink: allProduct(
      filter: { link: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTag: allProduct(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredArticlesByLink: allArticle(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesByTag: allArticle(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
  }
`;

export default ProductPage;
